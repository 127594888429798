<template>
    <div class="reactions-block-component">
        <div
            v-for="(items, key) in groupedReactions"
            :key="items[0].id"
            :class="{ 'active': getMyReaction(items) }"
            @click="setReaction(items)"
            class="reaction-item cursor-pointer q-mr-sm">
            <span class="noto-color-emoji">{{ key }}</span>
            {{ items.length }}
            <q-tooltip content-class="reactions-block-component__tooltip">
                {{ getUsersList(items) }}
            </q-tooltip>
        </div>
        <div class="reaction-item cursor-pointer add-button">
            <q-icon name="add">
                <q-menu class="emoji-menu overflow-hidden">
                    <q-list v-close-popup>
                        <EmojiBlock @select="selectEmoji" />
                    </q-list>
                </q-menu>
            </q-icon>
        </div>
    </div>
</template>

<script lang="ts">
    import {
        ChatMessageReactionDto,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import EmojiBlock from 'components/EmojiBlock/EmojiBlock.vue';
    import { computed, defineComponent, getCurrentInstance, PropType, ref } from 'vue';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { useAccountStore } from 'src/store/module-account';

    export default defineComponent({
        name: 'MessageReactions',

        components: {
            EmojiBlock,
        },

        props: {
            reactions: {
                type: Array as PropType<ChatMessageReactionDto[]>,
                required: true,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props) {
            const accountStore = useAccountStore();
            const app = getCurrentInstance();
            const chatHub = app?.appContext?.config.globalProperties.$commonHub as ChatPartialHub | null;
            const setReactionClicked = ref<boolean>(false);

            // Группируем реакции чтобы отобразить их количество
            const groupedReactions = computed<Record<string, ChatMessageReactionDto[]>>(() => {
                return Common.groupBy(props.reactions, 'emoji');
            });

            // Проверить, что в какой-то реакции есть реакция и текущего пользователя
            function getMyReaction(reactions: ChatMessageReactionDto[]): ChatMessageReactionDto | undefined {
                return reactions.find((x: ChatMessageReactionDto) => {
                    return x.author.id === (accountStore.getAccountInfo?.id ?? 0);
                });
            }

            // Проверить, что в какой-то реакции есть реакция и текущего пользователя
            // По конекретному emoji (т.е смайлику и тд)
            function getMyReactionByEmoji(emoji: string): ChatMessageReactionDto | undefined {
                const reactions = props.reactions.filter((x: ChatMessageReactionDto) => x.emoji === emoji);
                return getMyReaction(reactions);
            }

            function getFullUserName(author: UserBaseInfoDto): string {
                const { firstName, lastName, middleName } = author;

                return `${lastName} ${firstName} ${middleName || ''}`;
            }

            function getUsersList(items: ChatMessageReactionDto[]): string {
                return items.map((x: ChatMessageReactionDto) => {
                    return getFullUserName(x.author);
                }).join(', ');
            }

            function setReaction(reactions: ChatMessageReactionDto[]): void {
                if (setReactionClicked.value) {
                    return;
                }

                setReactionClicked.value = true; 

                const myReaction = getMyReaction(reactions);

                // Если такая реакция уже есть то повторным кликом удаляем ее
                if (myReaction) {
                    chatHub?.removeMessageReactionAsync(myReaction.id).then(() => {
                        setReactionClicked.value = false;
                    }).catch(() => {
                        setReactionClicked.value = false;
                    });
                } else {
                    // иначе добавляем
                    chatHub?.sendMessageReactionAsync({
                        emoji: reactions[0].emoji,
                        chatId: reactions[0].chatId,
                        messageId: reactions[0].messageId,
                    }).then(() => {
                        setReactionClicked.value = false; 
                    }).catch(() => {
                        setReactionClicked.value = false;
                    });
                }
            }

            function selectEmoji(emoji: any): void {
                const myReaction = getMyReactionByEmoji(emoji.native);

                // Если такая реакция уже есть то повторным выбором удаляем ее
                if (myReaction) {
                    chatHub?.removeMessageReactionAsync(myReaction.id);
                } else {
                    // иначе добавляем
                    chatHub?.sendMessageReactionAsync({
                        emoji: emoji.native,
                        chatId: props.reactions[0].chatId,
                        messageId: props.reactions[0].messageId,
                    });
                }
            }

            return {
                groupedReactions,
                getMyReaction,
                getUsersList,
                setReaction,
                selectEmoji,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .reactions-block-component {
        &:hover {
            .add-button {
                height: 18px !important;
                display: inline-flex !important;
            }
        }

        .reaction-item {
            display: inline-block;
            padding: 0px 4px;
            box-shadow: 0px 1px 2px rgb(19 29 49 / 16%);
            background-color: #FFFFFF;
            font-size: 12px;
            line-height: 20px;
            border-radius: 20px;
            color: $shade-8;

            &.active {
                background-color: $primary !important;
                color: #fff;
            }

            &.add-button {
                height: 0;
                display: block;
                background-color: $primary !important;
                align-items: center;
                vertical-align: bottom;

                ::v-deep(i) {
                    font-weight: bold;
                    color: #fff !important;
                }
            }

            &:hover {
                background-color: #E5F1FF;
            }

            .noto-color-emoji {
                font-size: 16px;
            }
        }
    }
</style>

<style lang="scss">
    .q-menu {
        &.emoji-menu {
            max-height: 420px !important;
        }
    }

    .reactions-block-component {
        &__tooltip {
            overflow: hidden;
            min-height: 54px !important;
        }
    }
</style>
