// eslint-disable-next-line comment-density/comment-density
import { IInterlocutor } from '../types/interfaces';
import { CallUserModel } from 'src/api/ApiClient';
import { Common } from 'src/helpers/Common';

// Преобразовать модель сервера в IInterlocutorParams
export default (interlocutor: CallUserModel): IInterlocutor => {
    return {
        personId: interlocutor.id,
        rfid: null,
        nickname: interlocutor.fullName,
        avatarUrl: interlocutor.previewPhotoPath,
        microphoneOn: !interlocutor.isMicrophoneMuted,
        sortId: Common.makeFakeId(),
        riseHandTimeUtc: interlocutor.riseHandTimeUtc,
        handOrder: 0,
        isTalking: false,
        isAutoPlayFailed: false,
        isSharingAllowed: interlocutor.isSharingAllowed ?? false,
        isMicrophoneAllowed: interlocutor.isMicrophoneAllowed ?? false,
        isCallManager: interlocutor.isCallManager,
        videoOn: false,
        hasVideoTrack: false,
    };
};
