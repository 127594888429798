import {
    AccountClient,
    AccountCurrentInfoResponseModel,
    AuthUrlsResponseModel,
    DocumentType,
    FileResponse,
    Result,
    ResultOf,
    RoleEnum,
    RoutePageNameEnum,
    UserBaseInfoDto, UserSelectModel,
} from 'src/api/ApiClient';
import Icons from 'components/ui/Icons';
import { OfficeDocumentsContentTypes } from 'components/ContentViewer/types';
import { NotifyErrors } from 'src/api/ResultOfMethods';
import FileSaver from 'file-saver';
import { localize } from 'src/services/LocalizationService/LocalizationService';
import { getApiClientInitialParams } from 'src/api/BaseApiClient';
import { useAccountStore } from 'src/store/module-account';
import { FunctionalComponent, SVGAttributes } from 'vue';

export enum VolumeUnits {
    b = 0,
    kb = 1024,
    mb = 1048576,
    gb = 1073741824,
}

export class Common {
    public static getFullName(userBaseInfo: UserBaseInfoDto | AccountCurrentInfoResponseModel): string {
        if (!userBaseInfo?.middleName) {
            return `${userBaseInfo?.lastName} ${userBaseInfo?.firstName}`;
        }

        return `${userBaseInfo?.lastName} ${userBaseInfo?.firstName} ${userBaseInfo?.middleName}`;
    }
    public static getNameAndFirstName(userBaseInfo: UserBaseInfoDto): string {
        if (!userBaseInfo?.middleName) {
            return `${userBaseInfo?.lastName} ${userBaseInfo?.firstName}`;
        }

        return `${userBaseInfo?.lastName} ${userBaseInfo?.firstName}`;
    }

    // Получить инициалы пользователя
    public static getShortUserNameByLastNameFirstName(lastName: string | undefined, firstName: string | undefined): string {
        if (!lastName) {
            return '';
        }

        return lastName[0] + (firstName ? firstName[0] : '');
    }

    // Обрезать строку до нужно длинны и подставить в конец три точки
    public static cutStr(str: string, count: number): string {
        if (str != null && str.length > count) {
            const ellipsis = '...';
            return str.slice(0, count - ellipsis.length) + ellipsis;
        }
        return str;
    }

    // вырезать все html теги из строки
    public static stripTags(str?: string): string {
        if (!str) {
            return '';
        }

        return str.replace(/<[^>]+>/g, '');
    }

    // Замнить ё на е
    public static replaceE(str: string): string {
        return str.trim().toLowerCase().replace(/ё/g, 'е');
    }

    // Сгруппировать коллекцию по полю объекта
    // группировка будет происходить по результату функции
    // eslint-disable-next-line @typescript-eslint/ban-types
    public static groupBy(array: Array<any>, keyFunc: Function | string): Record<string | number, any> {
        return array.reduce(function (obj: any, item: any) {
            const key = typeof keyFunc === 'function' ? keyFunc(item) : item[keyFunc];

            if (!obj.hasOwnProperty(key)) {
                obj[key] = [];
            }

            obj[key].push(item);
            return obj;
        }, {});
    }

    // Найти гет параметр в урле
    public static getUrlSearchParam(url: string, param: string): string | null {
        const regex = new RegExp('[#?&]' + param + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return null;
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * Фоматировать байты в нужную единицу измерения
     * @param bytes количество байт
     * @param fractionDigits число цифр после запятой
     * @param minUnitInBytes минимальная единица измерения в байтах
     * @param ceil округлять ли в большую сторону (пример: 0.001 кб => 1 кб)
     */
    public static formatBytes(bytes: number, fractionDigits: number = 2, minUnitInBytes: VolumeUnits = 0, ceil: boolean = false): string {
        if (bytes < 1024 && minUnitInBytes < VolumeUnits.kb) {
            return bytes + ' ' +  localize('б');
        } else {
            let size = bytes / 1024;
            let value = localize('Кб');

            if (size > 1024 || minUnitInBytes > VolumeUnits.kb) {
                size = size / 1024;
                value = localize('Мб');
            }

            if (size > 1024 || minUnitInBytes > VolumeUnits.mb) {
                size = size / 1024;
                value = localize('Гб');
            }

            if (ceil) {
                size = Math.ceil(size);
            }
            return size.toFixed(fractionDigits) + ' ' + value;
        }
    }

    // Фоматитьвать байты в Gb
    public static bytesToGb(bytes: number, fractionDigits: number = 2): string {
        const baseDivisor: number = 1024;
        const size = bytes / baseDivisor/baseDivisor/baseDivisor;
        return size.toFixed(fractionDigits);
    }

    // Получить массив с массивом элементов длинной step
    // Испотльзуется в пагинации для постраничной разбивки
    public static steper(allCount: number, step: number): number[][] {
        if (allCount) {
            const arr = [];
            const subarray = [];

            for (let i = 1; i <= allCount; i++) {
                arr.push(i);
            }

            for (let i = 0; i < Math.ceil(arr.length / step); i++) {
                subarray[i] = arr.slice(i * step, i * step + step);
            }

            return subarray;
        } else {
            return [];
        }
    };

    // Получить инициалы пользователя
    public static getShortUserName(name: string | undefined): string {
        if (!name) {
            return '';
        }
        const [lastName, firstName] = name.split(' ');
        return lastName[0] + (firstName ? firstName[0] : '');
    }

    // Парсим ИД упомянутых в сообщении пользователей, при наличии
    public static getForcedPushNotificationUserIds(message: string): number[] {
        const parser = new DOMParser().parseFromString(message, 'text/html').getElementsByClassName('userBlockName');

        const forcedPushNotificationUserIds: number[] = [];

        for (let i = 0; i < parser.length; i++) {
            if (forcedPushNotificationUserIds.filter((x: number) => x === Number(parser[i].id)).length === 0) {
                forcedPushNotificationUserIds.push(Number(parser[i].id));
            }
        }

        return forcedPushNotificationUserIds;
    }

    // Скопировать тектс в буфер обмена
    public static copyTextToClipboard(text: string): void {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Error: copy to clipboard failed', err);
        }

        document.body.removeChild(textArea);
    }

    // Скопировать текст в буфер обмена
    public static copyTextToClipboardAsync(text: string): Promise<void> {
        if (navigator.clipboard) {
            const result = navigator.clipboard.writeText(text);

            // ODIN-12699 почему-то writeText может вернуть undefined
            if (result) {
                return result;
            } else {
                return new Promise<void>((resolve) => {
                    this.copyTextToClipboard(text);
                    resolve();
                });
            }
        } else {
            return new Promise<void>((resolve) => {
                this.copyTextToClipboard(text);
                resolve();
            });
        }
    }

    /* Проверить есть ли у текущего пользователя хоть одна из указанных ролей */
    public static isHaveRole(...roles: RoleEnum[]): boolean {
        const accountStore = useAccountStore();
        const userRoles = accountStore.accountInfo ? accountStore.accountInfo.roles as number[] : [];

        return roles?.filter((r: number) => userRoles?.includes(r)).length > 0;
    }

    /**
     * Получить информацию о текущем пользователе в виде UserSelectModel
     */
    public static getCurrentUserAsSelectModel(): UserSelectModel {
        const accountStore = useAccountStore();
        const current = accountStore.getAccountInfo;
        return {
            firstName: current?.firstName ?? '',
            lastName: current?.lastName ?? '',
            middleName: current?.middleName ?? '',
            fullName: '',
            id: current?.id ?? 0,
            universities: [],
            cityName: '',
            universitiesOfString: '',
        } as UserSelectModel;
    }

    // Пользователь имеет роль преподавателя и выше
    public static isTeacherOrHigh(): boolean {
        return Common.isHaveRole(
            RoleEnum.SuperAdministratorRole,
            RoleEnum.UniversityAdministratorRole,
            RoleEnum.UniversityContentManagerRole,
            RoleEnum.AuditorRole,
            RoleEnum.DivisionAdministratorRole,
            RoleEnum.EducationalProgramAdministratorRole,
            RoleEnum.CohortAdministratorRole,
            RoleEnum.TeacherRole
        );
    }

    // Пользователь имеет только роль студента
    public static isOnlyStudent(): boolean {
        return !Common.isHaveRole(
                RoleEnum.AuditorRole,
                RoleEnum.CartographerRole,
                RoleEnum.DivisionAdministratorRole,
                RoleEnum.AuditorRole,
                RoleEnum.EducationalProgramAdministratorRole,
                RoleEnum.SuperAdministratorRole,
                RoleEnum.TeacherRole,
                RoleEnum.UniversityAdministratorRole,
                RoleEnum.CohortAdministratorRole,
                RoleEnum.UniversityContentManagerRole,
                RoleEnum.GroupCuratorRole) &&
            Common.isHaveRole(RoleEnum.StudentRole);
    }

    // получить иконку файла по contentType
    public static getFileIcon(contentType: string): FunctionalComponent<SVGAttributes> {
        const fileContentType = contentType.toLowerCase();

        if (fileContentType.match(/image.*/)) {
            return Icons.ImageIcon;
        } else if (fileContentType.match(/video.*/)) {
            return Icons.VideoIcon;
        } else if (fileContentType.match(/audio.*/)) {
            return Icons.AudioIcon;
        } else if (fileContentType === 'application/pdf') {
            return Icons.DocumentIcon;
        } else if (OfficeDocumentsContentTypes.indexOf(fileContentType) !== -1) {
            return Icons.DocumentIcon;
        } else {
            return Icons.FileIcon;
        }
    }

    // получить ссылку на иконку файла по contentType
    public static getFileIconPath(contentType: string): string {
        const fileContentType = contentType.toLowerCase();

        if (fileContentType.match(/image.*/)) {
            return 'images/icons/image.svg';
        } else if (fileContentType.match(/video.*/)) {
            return 'images/icons/video.svg';
        } else if (fileContentType === 'application/pdf') {
            return 'images/icons/document.svg';
        } else if (OfficeDocumentsContentTypes.indexOf(fileContentType) !== -1) {
            return 'images/icons/document.svg';
        } else {
            return 'images/icons/file.svg';
        }
    }

    // получить иконку файла по DocumentType
    public static getFileIconByDocumentType(documentType?: DocumentType): FunctionalComponent<SVGAttributes> {
        switch (documentType) {
            case DocumentType.Image:
                return Icons.ImageIcon;
            case DocumentType.Video:
                return Icons.VideoIcon;
            case DocumentType.Pdf:
            case DocumentType.Presentation:
            case DocumentType.Word:
            case DocumentType.Excel:
                return Icons.DocumentIcon;
            default:
                return Icons.FileIcon;
        }
    }

    // получить ссылку иконку файла по DocumentType
    public static geFileIconPathByDocumentType(documentType?: DocumentType | null): string {
        switch (documentType) {
            case DocumentType.Image:
                return '/images/icons/image.svg';
            case DocumentType.Video:
                return '/images/icons/video.svg';
            case DocumentType.Pdf:
            case DocumentType.Presentation:
            case DocumentType.Word:
            case DocumentType.Excel:
                return '/images/icons/document.svg';
            default:
                return '/images/icons/file.svg';
        }
    }

    public static getRouteName(route: RoutePageNameEnum|null|undefined): string {
        return !!route
            ? RoutePageNameEnum[route]
            : '';
    }

    // Обработать результат при скачивании файла
    public static async handleFileResponse(response: FileResponse|null): Promise<void> {
        if (!!response?.fileName) {
            const decodeFileName: string = decodeURI(response.fileName);
            FileSaver.saveAs(response.data, decodeFileName);
        } else {
            const odinResult: Result = JSON.parse(await response?.data.text() ?? '{}');
            NotifyErrors(odinResult);
        }
    }

    public static getSourceVersion(): string {
        return process.env.SOURCE_VERSION;
    }

    //В iframe подключаем шрифт в head
    public static includeFontToIframe(head: HTMLFrameElement): void {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = location.protocol + '//' + location.host + '/style/google-font.css';
        head.appendChild(link);
    }

    // Стилизация iframe
    public static setStyleToIframe(body: HTMLFrameElement): void {
        if (body) {
            body.style.fontFamily = 'Inter,Helvetica Neue,Arial,sans-serif';
            body.style.fontSize = '14px';
            body.style.wordBreak = 'break-word';
            body.style.fontWeight = '500';
            body.style.color = '2A2A34';
            body.style.lineHeight = '1.5';
            body.style.margin = '0px';
            body.style.padding = '0px';
            body.style.marginRight = '16px';

            //Устанавливаем занчение для заголовков H1
            const listH1:NodeList = body.querySelectorAll('h1');
            listH1.forEach((el: any) => {
                el.style.fontSize = '28px';
                el.style.fontFamily = 'Inter,Helvetica Neue,Arial,sans-serif';
                el.style.margin = '0px';
                el.style.marginBottom = '10px';
            });

            //Устанавливаем занчение для заголовков H2
            const listH2:NodeList = body.querySelectorAll('h2');
            listH2.forEach((el: any) => {
                el.style.fontSize = '18px';
                el.style.fontFamily = 'Inter,Helvetica Neue,Arial,sans-serif';
                el.style.margin = '0px';
                el.style.marginBottom = '10px';
                el.style.color = '2A2A34;';
            });

            //Устанавливаем занчение для заголовков H3,H4,H5,H6
            const listH3:NodeList = body.querySelectorAll('h3,h4,h5,h6,b,p,span');
            listH3.forEach((el: any) => {
                el.style.fontSize = '14px';
                el.style.fontFamily = 'Inter,Helvetica Neue,Arial,sans-serif';
                el.style.margin = '0px';
                el.style.marginBottom = '10px';
                el.style.color = '2A2A34;';
            });

            //Устанавливаем размер картинки
            const listImg:NodeList = body.querySelectorAll('img');
            listImg.forEach((el: any) => {
                el.style.width = '100%';
                el.style.height = 'auto';
            });
        }
    }

    public static makeFakeId(max?: number): number {
        return Math.floor(Math.random() * (max ?? 1000000));
    }

    public static generateRandomString(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // Проверить задано ли значение переменной, нужно когда ноль это значение.
    public static isSetOrZero(obj: any) : boolean {
        if (obj === null || typeof(obj) === typeof(undefined)) {
            return false;
        }
        return true;
    }

    // Получить урлы для аутентификации/регистрации через внешние системы
    public static async GetAuthUrls(redirect: string | null) : Promise<AuthUrlsResponseModel> {
        const result: ResultOf<AuthUrlsResponseModel> = await new AccountClient(getApiClientInitialParams()).getAuthUrls(redirect);

        if (!result.isSuccess) {
            NotifyErrors(result);
        }

        return result.entity;
    }

    /*
    * Копируем функционал из *partialHub классов в основной класс с подключением.
    * */
    public static applyMixins(derivedCtor: any, baseCtors: any[]): void {
        baseCtors.forEach((baseCtor) => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
                Object.defineProperty(
                    derivedCtor.prototype,
                    name,
                    Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
                    Object.create(null)
                );
            });
        });
    }

    public static isProductionHost(): boolean {
        return location.host === 'spa.odin.study' || location.host === 'odin.study' || location.host === 'www.odin.study';
    }

    /*
    * Приложение развернуто в canary релизе
    * */
    public static isCanary(): boolean {
        return process.env.CANARY === 'true';
    }

    public static parseJwt(token: string): Record<string, string> {
        if (!token) {
            return {};
        }

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /**
     * Показываем новогодние стили с 14 декабря по 14 января
     */
    public static isNewYearTime(): boolean {
        const currentDate = new Date();
        const year = currentDate.getFullYear();

        if (year === 2025) {
            return currentDate.getMonth() === 0 && currentDate.getDate() < 14;
        } else {
            return currentDate.getMonth() === 11 && currentDate.getDate() >= 14;
        }
    }

    public static randomInt(min: number, max: number): number {
        return min + Math.floor(Math.random() * (max - min + 1));
    }

}
