<template>
    <q-dialog
        v-model="isVisible"
        class="full-window window-height file-viewer-component window-width row justify-center items-center"
    >
        <div
            @click="hide"
            role="button"
            :aria-label="localize('Закрыть окно просмотра файла')"
            class="file-viewer-component__close close-button cursor-pointer"
        >
            <span>ESC</span>
            <q-icon name="close" size="32px"/>
        </div>

        <div @click="prevFile" class="file-viewer-component__arrow arrow-icon prev">
            <Icon
                v-if="currentFileIndex > 0"
                name="DisclosureIcon"
                size="46"
                role="button"
                tabindex="0"
                :aria-label="localize('Предыдущий файл')"
            />
        </div>

        <div @click="nextFile" class="file-viewer-component__arrow arrow-icon next">
            <Icon
                v-if="hasNextFile"
                name="DisclosureIcon"
                size="46"
                tabindex="0"
                :aria-label="localize('Следующий файл')"
            />
        </div>

        <div
            @click.stop
            :class="{'file-content-block--wide': fileType === ContentViewerFileType.OfficeDocument || fileType === ContentViewerFileType.PDF}"
            class="file-content-block"
        >
            <q-card class="file-viewer-component__body full-height">
                <div v-if="isLoading" class="text-center q-mt-xl">
                    <q-spinner-dots color="primary" size="4em"/>
                </div>

                <template v-else>
                    <template v-if="contentInfo && !contentInfo.canViewData">
                        <q-card v-if="contentInfo.contentDataViewPermissionInfo" class="q-mb-md q-pa-md text-center">
                            <lock-shield-warning-icon v-if="contentInfo.contentDataViewPermissionInfo.isTemporaryDenied"/>
                            <lock-shield-red-icon v-else/>

                            <div
                                v-if="contentInfo.contentDataViewPermissionInfo.isTemporaryDenied"
                                class="text-title q-my-md">{{ localize('Доступ временно закрыт') }}
                            </div>
                            <div v-else class="text-title q-my-md">{{ localize('Доступ закрыт') }}</div>

                            <div>
                                <template v-if="contentInfo.contentDataViewPermissionInfo.deniedReasonText.length > 1">
                                    <template
                                        v-for="(text,index) in contentInfo.contentDataViewPermissionInfo.deniedReasonText"
                                        :key="index"
                                    >
                                        <br v-if="index > 0"/>
                                        <span>{{ text }}</span>
                                    </template>
                                </template>
                                <template v-else>
                                    {{ contentInfo.contentDataViewPermissionInfo.deniedReasonText[0] }}
                                </template>
                                <div
                                    v-if="!!contentInfo.contentDataViewPermissionInfo.correctRoute"
                                    class="q-mt-md q-mb-md"
                                >
                                    <q-btn
                                        :to="contentInfo.contentDataViewPermissionInfo.correctRoute"
                                        color="primary"
                                    >
                                        <span>{{ localize('Перейти к активности')}}</span>
                                    </q-btn>
                                </div>
                            </div>
                        </q-card>
                        <q-card
                            v-else
                            class="server-error-block text-center q-mt-lg">
                            <q-card-section>
                                <lock-shield-red-icon/>
                                <div class="text-title q-mt-md">
                                    {{ localize('Доступ закрыт') }}
                                </div>
                            </q-card-section>
                        </q-card>
                    </template>

                    <template v-else>
                        <div
                            v-if="contentInfo && fileType"
                            class="title__body q-pa-md q-pb-sm flex justify-between items-center"
                        >
                            <div v-if="contentInfo" class="title page-title ellipsis">
                                <template v-if="contentInfo.isCallRecord && contentInfo.callRecordStartDateTime">
                                    {{ localize('Запись звонка от') }}
                                    {{ filterFullDateYearAndTime(contentInfo.callRecordStartDateTime) }}
                                </template>

                                <template v-else>
                                    {{ contentInfo.name }}
                                </template>
                            </div>
                        </div>
                        <div class="scroll-block">
                            <div v-if="!fileType" class="not-supported-file-block row justify-center items-center">
                                <div class="text-center">
                                    <div v-if="contentInfo" class="q-mt-lg q-mr-xs text-bold">{{ contentInfo.name }}</div>
                                    <div v-if="fileSize" class="q-mr-xs text-shade-8">{{ fileSize }}</div>
                                </div>
                            </div>
                            <div v-else class="d-flex text-center">
                                <VideoPlayer
                                    v-if="fileType === ContentViewerFileType.Video"
                                    :manifest-url="contentInfo?.streamingUrl"
                                    :file-name="contentInfo?.name"
                                    :is-encoding-failed="isEncodingFailed"
                                    :poster="contentInfo?.posterLink"
                                    :is-call-record="contentInfo?.isCallRecord" 
                                    :content-id="contentInfo?.contentId"
                                    :viewed-percentage="contentInfo?.viewedPercentage"
                                    :last-second="contentInfo?.lastSecond"
                                />
                                <iframe
                                    v-else-if="fileType === ContentViewerFileType.OfficeDocument"
                                    @error="setFrameLoadError"
                                    title="doc viewer"
                                    :src="officePreviewUrl"
                                    width="100%"
                                    height="99%"
                                    id="iframePreview"
                                ></iframe>
                                <PDFViewer
                                    v-else-if="fileType === ContentViewerFileType.PDF"
                                    :path-url="contentInfo?.filePath"
                                    :file-id="contentInfo?.fileId" />
                                <img
                                    v-else-if="fileType === ContentViewerFileType.Image"
                                    :src="contentInfo?.filePath || ''"
                                    alt=""
                                />
                                <latex-viewer
                                    v-else-if="fileType === ContentViewerFileType.LaTeX && contentInfo && contentInfo.contentId"
                                    :content-id="contentInfo.contentId"
                                />
                                <iframe
                                    v-else-if="fileType === ContentViewerFileType.Other"
                                    :title="contentInfo?.name"
                                    style="overflow-y: hidden;"
                                    onload="style.minHeight = (this.contentDocument.body.scrollHeight + 40) + 'px';"
                                    :src="contentInfo?.filePath || ''"
                                >
                                </iframe>
                            </div>
                        </div>
                        <div class="file-viewer-component__bottom q-pa-md">
                            <div v-if="contentInfo" class="flex justify-between items-center">
                                <Rating
                                    v-if="options?.content && fileType"
                                    :entity-id="contentInfo.contentId"
                                    :entity-type="ratingEntityType"
                                    class="file-viewer-component__rating q-mr-sm"
                                />
                                <div
                                    class="file-viewer-component__bottom_right row justify-center"
                                    :class="{full: !fileType}"
                                >
                                    <q-btn
                                        v-if="canDownload && contentInfo.fileId"
                                        type="a"
                                        flat
                                        @click="downloadFile(contentInfo.fileId)"
                                        color="primary"
                                        class="file-viewer-component__bottom_button q-manual-focusable--focused q-mr-md"
                                    >
                                        <DownloadIcon class="q-mr-sm"/>
                                        <span class="link">{{ localize('Скачать') }}</span>
                                    </q-btn>
                                    <router-link
                                        v-if="currentContentId"
                                        :to="contentPageRoute"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <q-btn
                                            flat
                                            class="file-viewer-component__bottom_button q-manual-focusable--focused q-mr-sm"
                                            color="primary">
                                            <OpenNewIcon class="q-mr-xs"/>
                                            <span class="link">{{ localize('Страница материала') }}</span>
                                        </q-btn>
                                    </router-link>
                                    <q-btn
                                        v-if="options?.isAttachmentMode"
                                        flat
                                        @click="attachContent"
                                        class="file-viewer-component__bottom_button q-manual-focusable--focused q-mr-sm attachment-btn"
                                        color="primary">
                                        <CheckIcon class="q-mr-xs"/>
                                        <span class="link">{{ localize('Выбрать материал') }}</span>
                                    </q-btn>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </q-card>
        </div>
    </q-dialog>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import {
        ContentViewerFileType,
        ContentViewerType,
        IContentViewerOptions,
        OfficeDocumentsContentTypes,
    } from 'components/ContentViewer/types';
    import {
        ContentClient,
        ContentEnum,
        ContentFullScreenResponseModel,
        EncodingState,
        FileClient,
        getApiClientInitialParams,
        RatingEntityType,
        ResultOf,
        RoutePageNameEnum,
        LibraryNodeDto,
        ContentTypeForActivity,
    } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import LatexViewer from 'src/components/LatexViewer';
    import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
    import useFilters from 'src/helpers/custom-hooks/useFilters';
    import { RouteLocationRaw, useRouter } from 'vue-router';

    export default defineComponent({
        name: 'ContentViewer',

        components: {
            LatexViewer
        },

        emits: ['on-attach'],
        props: {
            // Показывать ли неперекодированное видео
            playNotEncodedVideo: {
                required: false,
                type: Boolean,
                default: false
            },
        },
        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $router = useRouter();

            // Для этих типов материала предпросмотра нет
            const contentTypesNotForView: ContentEnum[] = [
                ContentEnum.ActivityPlan,
                ContentEnum.DisciplinePlan,
                ContentEnum.EducationalProgramPlan,
                ContentEnum.TestTrain,
                ContentEnum.Test,
                ContentEnum.CodeTask,
                ContentEnum.ScormPacket,
                ContentEnum.Text,
                // ContentEnum.Survey,
            ];

            // Для этих типов материала предпросмотра нет
            const contentActivityTypesNotForView: ContentTypeForActivity[] = [
                ContentTypeForActivity.TextContent,
                ContentTypeForActivity.Test,
                ContentTypeForActivity.TestTrain,
                ContentTypeForActivity.ScormPacketContent,
                ContentTypeForActivity.CodeTaskContent,
            ];

            // node пробрасывается и возвращается обратно в компонент в emit
            // Разобратьcя, нужно ли вообще это поле
            // и почему его нельзя взять из родительского компонента
            let node: LibraryNodeDto | null | undefined = null;

            // Хук фильтра
            const { filterFullDateYearAndTime } = useFilters();

            // Отображается ли компонент
            const isVisible = ref<boolean>(false);

            // Происходит ли загрузка данных
            const isLoading = ref<boolean>(true);
            const contentInfo = ref<ContentFullScreenResponseModel | null>(null);

            // Тип сущности для которой выставляем рейтинг
            const ratingEntityType: number = Number(RatingEntityType.Content);

            // Что просматриваем - материал или просто файл */
            const fileType = ref<ContentViewerFileType | null>(null);
            const currentFileIndex = ref<number>(0);

            const options = ref<IContentViewerOptions | null>(null);

            // id текущего материала, с учетом того, что может быть перелистывание материалов
            const currentContentId = computed<number | undefined>(() => {
                if (options.value?.type === ContentViewerType.Content) {
                    if (options.value.contentItems && options.value.contentItems.length) {
                        return options.value.contentItems[currentFileIndex.value].id;
                    } else {
                        return options.value.content?.id;
                    }
                }

                return undefined;
            });

            // Ссылка на страницу материала
            const contentPageRoute = computed<string>(() => {
                let routeName = Common.getRouteName(RoutePageNameEnum.ContentInfo);
                switch (contentInfo.value?.type) {
                    case ContentEnum.ActivityPlan:
                        routeName = Common.getRouteName(RoutePageNameEnum.ActivityPlanInfo);
                        break;
                    case ContentEnum.DisciplinePlan:
                        routeName = Common.getRouteName(RoutePageNameEnum.DisciplinePlanInfo);
                        break;
                    case ContentEnum.EducationalProgramPlan:
                        routeName = Common.getRouteName(RoutePageNameEnum.EducationalProgramPlanInfo);
                        break;
                }

                const path = $router.resolve({
                    name: routeName,
                    params: { id: currentContentId.value?.toString() ?? '' }
                });

                return path.path;
            });

            // Видео нельзя скачивать
            const canDownload = computed<boolean>(() => {
                return fileType.value !== ContentViewerFileType.Video;
            });

            // Перекодировалось видео или нет
            const isEncodingFailed = computed<boolean>(() => {
                return contentInfo.value?.encodingState === EncodingState.EncodingFailed;
            });

            // Ссылка на приложения для открытия офисных документов
            const officePreviewUrl = computed<string>(() => {
                const url = contentInfo.value?.filePath || '';
                return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
            });

            // Преобразование размера материала из байт в Кб, Мб или Гб в зависимости от размера
            const fileSize = computed<string>(() => {
                if (contentInfo.value && contentInfo.value.fileSize) {
                    return Common.formatBytes(contentInfo.value.fileSize);
                } else {
                    return '';
                }
            });

            // Есть ли следующий файл для просмотра,
            // Если нет, стрелка "вправо" скрывается
            const hasNextFile = computed<boolean>(() => {
                if (options.value?.type === ContentViewerType.File) {
                    return (options.value.fileIds && options.value.fileIds.length - 1) !== currentFileIndex.value;
                } else {
                    if (options.value?.contentItems) {
                        return options.value.contentItems.length - 1 !== currentFileIndex.value;
                    } else {
                        return false;
                    }
                }
            });

            // Вызывается снаружи для отображения диалога просмотра контента
            function show(params: IContentViewerOptions): void {
                node = params.node;

                if (params.contentItems) {
                    params.contentItems = prepareContentItems(params.contentItems, params.contentEnumSource);
                }

                options.value = params;

                // Смотрим, что передали, файл или контент
                // И определяем, нужно ли показывать стрелки "вправо/влево"
                if (params.type === ContentViewerType.File) {
                    isVisible.value = true;
                    let fileId = params.fileId;

                    if (params.fileIds) {
                        currentFileIndex.value = params.indexFile || 0;
                        fileId = params.fileIds[currentFileIndex.value];
                    }

                    loadFileInfo(fileId!);
                } else {
                    if (params.content) {
                        const redirect = checkNeedRedirect(params.content, params.contentEnumSource);

                        if (redirect) {
                            window.open($router.resolve(redirect).href, '_blank');
                            return;
                        }
                    }

                    isVisible.value = true;
                    let contentId = params.content?.id;

                    if (params.contentItems) {
                        currentFileIndex.value = params.contentItems.findIndex((x) => x.id === contentId);
                        contentId = params.contentItems[currentFileIndex.value].id;
                    }

                    loadFileInfo(contentId!);
                }
            }

            // Фильтруем материалы для которых нет предпросмотра
            function prepareContentItems(contentItems: IContentViewerOptions['contentItems'], enumSource: IContentViewerOptions['contentEnumSource']): IContentViewerOptions['contentItems'] {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return contentItems!.filter((item: IContentViewerOptions['content']) => {
                    let type: ContentTypeForActivity | ContentEnum | undefined;

                    if ('type' in item!) {
                        type = item.type;
                    }

                    if ('contentInfo' in item!) {
                        type = item.contentInfo?.contentType;
                    }

                    if (enumSource === 'ContentEnum') {
                        return !contentTypesNotForView.includes(type as ContentEnum);
                    }

                    if (enumSource === 'ContentTypeForActivity') {
                        return !contentActivityTypesNotForView.includes(type as ContentTypeForActivity);
                    }

                    return true;
                });
            }

            // Определяем страницу материала, на которую нужно средиректить
            function checkNeedRedirect(content: IContentViewerOptions['content'], contentEnumSource: IContentViewerOptions['contentEnumSource']): RouteLocationRaw | null {
                let type: ContentTypeForActivity | ContentEnum | undefined;

                if (content && 'type' in content) {
                    type = content.type;
                }

                if (content && 'contentInfo' in content) {
                    type = content.contentInfo?.contentType;
                }

                const rawRoute = {
                    name: '',
                    params: { id: content?.id },
                };

                if (contentEnumSource === 'ContentEnum') {
                    if (contentTypesNotForView.includes(type as ContentEnum)) {
                        if (type === ContentEnum.ActivityPlan) {
                            rawRoute.name = Common.getRouteName(RoutePageNameEnum.ActivityPlanInfo);
                        } else if (type === ContentEnum.DisciplinePlan) {
                            rawRoute.name = Common.getRouteName(RoutePageNameEnum.DisciplinePlanInfo);
                        } else if (type === ContentEnum.EducationalProgramPlan) {
                            rawRoute.name = Common.getRouteName(RoutePageNameEnum.EducationalProgramPlanInfo);
                        } else {
                            rawRoute.name = Common.getRouteName(RoutePageNameEnum.ContentInfo);
                        }

                        return rawRoute;
                    }
                }

                if (contentEnumSource === 'ContentTypeForActivity') {
                    if (contentActivityTypesNotForView.includes(type as ContentTypeForActivity)) {
                        rawRoute.name = Common.getRouteName(RoutePageNameEnum.ContentInfo);
                        return rawRoute;
                    }
                }

                return null;
            }

            // Обработка скачивания файла
            async function downloadFile(id: number): Promise<void> {
                const request = await new FileClient(getApiClientInitialParams()).getPresignedDownloadUrl(id);

                if (request.isSuccess) {
                    const link = document.createElement('a');
                    link.setAttribute('href', request.entity.value);
                    link.setAttribute('download', 'download');
                    link.click();
                }
            }

            // Загрузить информацию о контенте по переданному id
            async function loadFileInfo(id: number): Promise<void> {
                isLoading.value = true;
                let result: ResultOf<ContentFullScreenResponseModel> | null;
                const lifeTimeInMinutes = 10;

                if (options.value?.type === ContentViewerType.File) {
                    result = await new ContentClient(getApiClientInitialParams())
                        .getFileFullScreenInfo(id, props.playNotEncodedVideo, lifeTimeInMinutes);
                } else {
                    result = await new ContentClient(getApiClientInitialParams())
                        .getContentFullScreenInfo(id, lifeTimeInMinutes);
                }

                if (result.isSuccess) {
                    contentInfo.value = result.entity;
                    setContentInfo();
                } else {
                    NotifyErrors(result);
                }
                isLoading.value = false;
            }

            function setContentInfo(): void {
                fileType.value = null;
                // Если есть поле streamingUrl, то это видео
                if (contentInfo.value?.streamingUrl || contentInfo.value?.isCallRecord) {
                    fileType.value = ContentViewerFileType.Video;
                } else {
                    if (contentInfo.value?.encodingState !== undefined && contentInfo.value?.encodingState !== null) {
                        // У не видео материалов/файлов encodingState будет 0
                        // Поэтому определяем файл по fileContentType
                        if (contentInfo.value.encodingState === EncodingState.NoEncoding) {
                            if (contentInfo.value.fileContentType) {
                                setFileType();
                            } else {
                                fileType.value = null;
                            }
                        } else {
                            // Если encodingState != 0 то это тоже видео
                            fileType.value = ContentViewerFileType.Video;
                        }
                    }
                }
                if (contentInfo.value?.type === ContentEnum.LaTeX) {
                    fileType.value = ContentViewerFileType.LaTeX;
                }
            }

            // Определяем тип файла по fileContentType
            function setFileType(): void {
                const fileContentType = (contentInfo.value?.fileContentType || '').toLowerCase();

                if (fileContentType.match(/image.*/)) {
                    fileType.value = ContentViewerFileType.Image;
                } else if (fileContentType === 'application/pdf') {
                    fileType.value = ContentViewerFileType.PDF;
                } else if (fileContentType === 'application/rtf') {
                    fileType.value = null;
                } else if (OfficeDocumentsContentTypes.indexOf(fileContentType) !== -1) {
                    fileType.value = ContentViewerFileType.OfficeDocument;
                } else {
                    fileType.value = null;
                }
            }

            function hide(): void {
                isVisible.value = false;
            }

            function hideByEsc(event: KeyboardEvent): void {
                if (event.key === 'Escape') {
                    hide();
                }
            }

            function setFrameLoadError(): void {
                fileType.value = null;
            }

            // Выбрать этот материал для чего-либо
            // Вызываем событие on-attach чтобы родительский компонент кго обработал
            function attachContent(): void {
                context.emit('on-attach', {
                    ...node,
                    fileId: options.value?.content?.id
                });

                hide();
            }

            // Показать предыдущий файл
            function prevFile(): void {
                if (currentFileIndex.value > 0) {
                    currentFileIndex.value--;
                }

                if (options.value?.fileIds) {
                    loadFileInfo(options.value.fileIds[currentFileIndex.value]);
                } else if (options.value?.contentItems) {
                    loadFileInfo(options.value.contentItems[currentFileIndex.value].id);
                }
            }

            // Показать следующий файл
            function nextFile(): void {
                if (options.value?.fileIds) {
                    if (currentFileIndex.value < options.value.fileIds.length - 1) {
                        currentFileIndex.value++;
                    }

                    loadFileInfo(options.value.fileIds[currentFileIndex.value]);
                } else if (options.value?.contentItems) {
                    if (currentFileIndex.value < options.value.contentItems.length - 1) {
                        currentFileIndex.value++;
                    }

                    loadFileInfo(options.value.contentItems[currentFileIndex.value].id);
                }
            }

            onMounted(() => {
                document.addEventListener('keyup', hideByEsc);
            });

            onBeforeUnmount(() => {
                document.removeEventListener('keyup', hideByEsc);
            });

            return {
                ContentViewerFileType,
                isVisible,
                isLoading,
                contentInfo,
                ratingEntityType,
                fileType,
                currentFileIndex,
                options,
                currentContentId,
                contentPageRoute,
                canDownload,
                isEncodingFailed,
                officePreviewUrl,
                fileSize,
                hasNextFile,
                show,
                hide,
                downloadFile,
                hideByEsc,
                setFrameLoadError,
                attachContent,
                prevFile,
                nextFile,
                localize,
                filterFullDateYearAndTime,
            };
        }
    });
</script>

<style lang="scss">
    .file-viewer-component {
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 7000;

        &__close {
            position: fixed;
            top: 20px;
            right: 24px;
            color: #b4b9d1;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
        }

        &__arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: none !important;
        }

        &__body {
            height: auto !important;
            max-height: 930px;
        }

        .attachment-btn {
            .q-icon {
                color: white !important;
            }
        }


        .file-content-block {
            min-width: 480px;
            max-width: 66%;
            max-height: 100%;

            &--wide {
                max-width: 75%;
                min-width: 75%;
            }

            .title__body {
                padding-bottom: 8px;
            }

            .title {
                max-width: 100%;
                padding-top: 0;
            }

            .scroll-block {
                max-height: calc(100% - 150px);
                overflow-x: hidden;
            }

            iframe {
                width: 100%;
                max-width: 100%;
                max-height: calc(100vh - 200px);
                height: 100vh;
                min-height: auto !important;
            }

            img {
                max-width: 100%;
                max-height: calc(100vh - 200px);
            }
        }

        &__bottom {
            padding-top: 8px;

            &_right {
                &.full {
                    width: 100%;
                }
            }

            &_button {
                &.q-manual-focusable--focused {
                    background-color: $subtle-accent;

                    svg {
                        ::v-deep(path) {
                            stroke: $link;
                        }
                    }
                }
            }
        }

        .arrow-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 9;

            &.prev {
                left: 4.5%;
                transform: translateY(-50%) rotateZ(180deg);
            }

            &.next {
                right: 4.5%;
            }
        }
    }

    @media (min-width: 1930px) {
        .file-viewer-component {
            &__body {
                max-height: 85%;
            }
        }
    }

    @media (max-width: 960px) {
        .file-viewer-component {
            .file-content-block {
                width: 98%;
                min-width: auto;
                max-width: 85%;
                max-height: 85%;

            }
        }
    }

    @media (max-width: 700px) {
        .file-viewer-component {
            .file-content-block {
                width: 98%;
                min-width: auto;
                max-width: 94%;
                max-height: 85%;

                iframe {
                    min-height: 300px;
                }

            }

            &__bottom {
                &_right {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }

                &_button {
                    &:first-child {
                        order: 2;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    &:last-child {
                        order: 1;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }

            &__rating {
                order: 3;
                margin-top: 15px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @media (max-width: 650px){
        .file-viewer-component .file-content-block iframe {
            max-height: calc(100vh - 290px);
        }
    }

</style>
<style lang="scss" scoped>
    .page-title {
        margin: 0 !important;
        padding: 0 !important;
    }
</style>
